@import '../../index.scss';

.LangSelection-container {
  position: absolute;
  right: 2.5vw;
  top: 2.5vw;
}

.Download-Page {
  padding: 5vh 6vw 0;
  align-content: center;
}

a#launch {
  width: 100%;
}

img[alt='avatar'] {
  width: 5vw;
  height: 5vw;
  display: inline;
  object-fit: cover;
  border-radius: 100%;
  filter: drop-shadow(0.05vw 0.05vw 0.4vw rgba(0, 0, 0, 0.5));
  margin-top: -1.5vw;
  margin-right: 1vw;

  @include respond-above(md) {
    margin-top: -0.5vw;
    margin-right: 0.5vw;
    width: 3vw;
    height: 3vw;
  }
}

.username {
  font-weight: 700;
}