@import '../../index.scss';

.Badges {
  display: flex;
  align-items: center;
  justify-content: space-around;

  &.large {
    padding: 3vh;
    a {
      padding: 2vw;

      @include respond-above(sm) {
        height: 11vw; /* Using vw here because we scale the width to the height */
        max-height: 20vh;
      }

      @include respond-below(sm) {
        padding-top: 3vh;
        width: 100%;
        max-width: 35vh; /* Using vh here because we scale the height to the width */
        min-width: 120px;
        min-height: 60px;
      }

      img {
        @include respond-below(sm) {
          width: 100%;
          min-width: 120px;
          min-height: 60px;
        }
      }
    }

    @include respond-below(sm) {
      flex-direction: column;
    }
  }

  &.small {
    justify-content: flex-start;
    a {
      &:first-child {
        margin-right: 2px;
      }
      max-height: 75px;
      height: 11vw;

      @include respond-below(sm) {
        min-height: 40px;
        max-width: 35vh; /* Using vh here because we scale the height to the width */
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    user-select: none;
    transition: transform 200ms;

    &:active,
    &:hover {
      transform: scale(1.05);
    }

    img {
      display: block;
      object-fit: contain;
      height: 100%;
      width: auto;
      user-select: none;
    }
  }
}
