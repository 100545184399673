@import 'index.scss';

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgb(239, 239, 240) 100%
  );
}

.App-logo {
  position: absolute;
  top: 3vw;
  left: 3vw;
  height: clamp(2rem, 3vw, 3rem);
  z-index: 10;
}

.App-logo img {
  max-height: 100%;
}

.App-section-container-bg {
  background-color: #2a2e43;
  background-size: cover;
  z-index: -5;
}

.App-section-container-bg::before {
  content: '';
  background-image: url('./assets/images/background.jpg');
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.25;
}

.App-section-container-small {
  position: relative;
  height: 50vh;
  margin: 30vh auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 1;

  .App-section-container-small-badges {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 10vw;

    img {
      object-fit: contain;
      height: 100%;
      width: auto;
      margin: 0 0.5em;
    }
  }

  @include respond-below(md) {
    & {
      height: 30vh;
      margin: 20vh auto;
    }
  }
}

.App-section-container {
  position: relative;
  display: grid;
  gap: 2vw;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  padding: 3rem 10vw;
  pointer-events: none;
  z-index: 1;

  @include respond-below(sm) {
    padding: 3rem 3rem;
    gap: 2em;
  }

  @include respond-below(xs) {
    .xs-order-2 {
      order: 2;
    }
  }

  .App-section-phones-container {
    display: grid;
    grid-template-columns: 40% 15% 35%;
    grid-template-rows: 5% 90% 5%;
  }

  & > * {
    pointer-events: all;
  }
}

.App-section-container-flex {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  &.contained {
    @include respond-above(sm) {
      margin: 0 auto;
    }
    max-width: 800px;
    padding: 0 1rem;
    margin-top: 4rem;
  }
}

.App-section-h2 {
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 200;
  font-size: clamp(1.5rem, 3vw, 3rem);
  &.bigger {
    font-weight: 600;
    font-size: clamp(1.5rem, 4vw, 4rem);
  }
}
.App-section-h3 {
  position: relative;
  font-weight: 300;
  font-size: clamp(1.3rem, 2vw, 2rem);
}
.App-section-h4 {
  position: relative;
  font-weight: 800;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
}

.App-challenge {
  position: relative;
  margin-left: 5vw;
  z-index: -1;
}
.App-challenge::before {
  content: '';
  position: absolute;
  background: linear-gradient(145deg, #ff5975 0%, #5a68ff 100%) 0% 0% no-repeat
    padding-box;
  left: -5vw;
  top: -20vw;
  width: 55vw;
  height: 55vw;
  border-radius: 50%;

  @include respond-below(sm) {
    & {
      width: 450px;
      height: 450px;
      left: -50px;
      top: -120px;
    }
  }
}

.App-section-button {
  &:hover .Icon {
    transform: translateX(10px);
  }

  a {
    &:hover span {
      transform: translateX(10px);
    }
  }
}

.forbusiness {
  height: 50vh;
}
