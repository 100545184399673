.Icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.transition {
  transition: all 200ms ease;
}
