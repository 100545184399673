@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-5xl;
    @apply font-black;
    @apply text-gray-700;
  }
  h2 {
    @apply text-4xl;
    @apply font-black;
    @apply text-gray-700;
  }
  h3 {
    @apply text-3xl;
    @apply font-bold;
    @apply text-gray-700;
  }
  h4 {
    @apply text-2xl;
    @apply font-bold;
    @apply text-gray-700;
  }
  h5 {
    @apply text-xl;
    @apply font-bold;
    @apply text-gray-700;
  }
  h6 {
    @apply text-base;
    @apply font-bold;
    @apply text-gray-700;
  }

  ol {
    @apply text-base;
    @apply font-light;
    @apply text-gray-600;
    @apply ml-0;
    @apply my-2;
    @apply list-decimal;
    @apply list-inside;
  }

  ul {
    @apply text-base;
    @apply list-disc;
    @apply font-light;
    @apply ml-6;
    @apply text-gray-600;
  }

  ol > li {
    @apply my-4;
  }

  li {
    @apply my-2;
  }

  p {
    @apply my-2;
    @apply text-gray-600;
    @apply font-light;
  }

  pre {
    @apply my-2;
    @apply p-2;
    @apply bg-gray-200;
    @apply border-gray-300;
    @apply border-2;
    @apply rounded-md;
  }

  hr {
    @apply my-2;
  }

  blockquote {
    @apply border-l-4;
    @apply border-gray-400;
    @apply py-1 pl-6;
    @apply text-gray-500;
  }

  strong {
    @apply font-bold;
  }
}
