$avatarSize: 55vw;
$responsiveAvatarSize: clamp(150px, $avatarSize, 350px);
$badgeSize: $avatarSize / 4;
$responsiveBadgeSize: clamp(37px, $badgeSize, 87px);

.ProfilePage-container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  z-index: 1;
  overflow: hidden;

  .ProfilePage-logo {
    position: absolute;
    left: 2em;
    top: 2em;
  }

  h2,
  h3 {
    color: white;
    font-family: 'Nunito Sans';
  }
  h2 {
    font-weight: 900;
    margin: 0;
    font-size: clamp(1.5em, 6.5vw, 3em);
  }
  h3 {
    font-weight: 400;
    margin: 0 0 2em 0;
    font-size: clamp(1em, 5vw, 2.5em);
  }
}

.ProfilePage-container-profile {
  position: relative;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .ProfilePage-container-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80vw;
  }

  .ProfilePage-avatar-container {
    position: relative;
    width: $responsiveAvatarSize;
    height: $responsiveAvatarSize;
    margin-top: 2em;

    .ProfilePage-avatar {
      width: $responsiveAvatarSize;
      height: $responsiveAvatarSize;
      border-radius: $avatarSize;
      border: $avatarSize / 30 solid white;
    }

    .ProfilePage-avatar-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: yellow;
      width: $responsiveBadgeSize;
      height: $responsiveBadgeSize;
      border-radius: 30vw;

      .ProfilePage-avatar-badge-text {
        color: black;
        font-size: clamp(1em, 6vw, 2.5em);
        font-weight: 900;
      }
    }
  }
}

.ProfilePage-info {
  font-size: clamp(1.2em, 3vw, 2.5em);

  .info-row {
    display: flex;
    align-items: center;
    height: 2em;
    transition: all 200ms ease;
    cursor: pointer;

    &:hover {
      color: #ff5975;
    }
  }
}

footer.Footer-footer.hidden {
  display: none;
}

a.App-logo.hidden {
  display: none;
}
