@import '../../index.scss';

.Download-Page-Card {
  background-color: transparent;
  box-shadow: none;
  max-width: 100%;
  padding: 2.5vw;
  text-align: center;
  margin-top: 7vh;
  user-select: none;

  img[alt='titleLogo'] {
    height: 8vw;
    display: inline;

    @include respond-above(md) {
      height: 6vw;
    }
  }

  h1 {
    font-size: 8vw;

    @include respond-above(md) {
      font-size: 6vw;
    }
  }


  h2 {
    font-weight: 400;
    font-size: 5vw;
    line-height: 8vw;

    @include respond-above(md) {
      font-size: 2.5vw;
    }
  }

  p {
    font-size: 3vw;

    @include respond-above(md) {
      font-size: 2vw;
    }
  }
}
