@import '../../index.scss';

.LangSelection-container {
  position: absolute;
  right: 2.5vw;
  top: 2.5vw;
}

.Download-Page {
  padding: 5vh 6vw 0;
  align-content: center;
  display: flex;
  flex: 1;
}
