.sl-button {
  font-family: 'Nunito Sans';
  font-size: 1.5em;
  font-weight: 800;
  background-color: #ff5975;
  color: white;
  padding: 0.5em 2em;
  border: 3px solid #ff5975;
  border-radius: 100vw;
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    background-color: transparent;
    color: #ff5975;
    border: 3px solid #ff5975;
  }

  &:active {
    background-color: #ff5975;
    color: white;
    border: 3px solid #ff5975;
    transform: translateY(3px);
  }
}
