@import '../../index.scss';

.Footer-footer {
  background: #454863d9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  .Footer-color-container {
    display: flex;
    height: 0.5em;
    width: 100%;
    background: transparent linear-gradient(270deg, #5a68ff 0%, #ff5975 100%) 0%
      0% no-repeat padding-box;
  }

  .Footer-text-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 2em;
    padding: 1em;

    p,
    a {
      font-size: 0.7rem;
    }

    .no-wrap {
      white-space: nowrap;
    }
  }

  .Footer-nav ul {
    list-style: none;

    @include respond-above(xxs) {
      /* Display list vertically on narrow screens */
      display: flex;
    }

    li {
      margin: 0 1em;
      .link {
        text-decoration: none;
      }
    }
  }

  *,
  .link {
    color: rgba(255, 255, 255, 0.4);
  }
}
