.LangSelection-container {
  position: absolute;
  right: 2.5vw;
}

.Info-Page {
  padding: 3rem 6vw;
}

.Info-Page-Card {
  background-color: transparent;
  box-shadow: none;
  max-width: 100%;
  padding: 2.5vw;
  h1 {
    width: 90%;
  }
}
