$breakpoint-tablet: 1100px;
$primary: #ff5975;
$primary-1: $primary;
$primary-2: #ff786c;
$primary-3: #ff8e65;
$primary-4: #ffa45e;
$primary-5: #ffb858;
$primary-6: #ffcc51;
$primary-7: #ffe14a;
$primary-8: #ffff40;

//
// FONT FACE
//––––––––––––––––––––––––––––––––––––––––––––––––––
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat.woff2') format('woff2');
  font-style: normal;
  font-weight: 100 200 300 400 500 600 700 800 900;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./assets/fonts/NunitoSans.woff2') format('woff2');
  font-style: normal;
  font-weight: 100 200 300 400 500 600 700 800 900;
}

//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints.
$breakpoints: (
  xxs: 432px,
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
);

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  position: relative;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: -10;
}

html,
body,
#root {
  color: #35384d;
}

h1 {
  font-family: 'Montserat', sans-serif;
  margin-bottom: 0.5em;
  font-size: 6vw;
  @include respond-above(sm) {
    font-size: 2.5em;
  }
}
h2 {
  margin-bottom: 0.5em;
  font-size: 4.5vw;
  @include respond-above(sm) {
    font-size: 2em;
  }
}
h3 {
  margin-bottom: 0.5em;
  font-size: 4vw;
  @include respond-above(sm) {
    font-size: 1.5em;
  }
}
h4 {
  margin-bottom: 0.5em;
  font-size: 3.5vw;
  @include respond-above(sm) {
    font-size: 1.3em;
  }
}
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserat', sans-serif;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

p,
ul,
ol {
  font-size: 1.1em;
}

a,
a:visited {
  color: $primary;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li {
  cursor: default;
}

::selection {
  background: $primary-4;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-white {
  color: white;
}

img {
  max-width: 100%;
}
img[width] {
  width: auto; /* Defer to max-width */
}
img[width][height] {
  height: auto; /* Preserve aspect ratio */
}

@for $i from 1 through 13 {
  @for $j from 1 through 13 {
    .gc-#{$i}-#{$j} {
      grid-column: calc(#{$i} / #{$j});
    }
    .gr-#{$i}-#{$j} {
      grid-row: calc(#{$i} / #{$j});
    }
  }
}
