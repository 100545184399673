.PhoneVideo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  max-width: 375px;
  box-shadow: 0 5px 30px rgb(0 0 0 / 25%);
  border-radius: 20px;
  overflow: hidden;
}

.phone-frame {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: auto;
  max-width: 375px;
}

.video-canvas {
  z-index: -1;
  width: 100%;
  border-radius: 20px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
