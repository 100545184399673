@import '../../index.scss';

.LangSelection-container {
  display: flex;

  .lang-button {
    cursor: pointer;
    color: $primary;

    &.selected {
      text-decoration: underline;
    }
  }
}
